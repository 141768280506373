import { getGeoLocation } from './geoLocation';

let matomoInitialized = false;

export const initMatomo = () => {
  if (matomoInitialized) return;

  var _paq = window._paq = window._paq || [];
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u = "//analytics.firewallsrl.com/";
    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    _paq.push(['setSiteId', '7']);
    var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
    g.async = true; g.src = u + 'matomo.js'; s.parentNode.insertBefore(g, s);
  })();

  matomoInitialized = true;
  console.log('Matomo initialized');


  getGeoLocation();
};