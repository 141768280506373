const documents = [
    { title: 'Catalogo generale', link: '/pdf/1_brochure.pdf' },
    { title: 'Presentazione', link: '/pdf/2_grec_presentation.pdf' },
    { title: 'Manuale uso e manutenzione Agitatori Laterali', link: '/pdf/3_LATERALI_IOM_GREC_2021_ITA.pdf' },
    { title: 'Manuale uso e manutenzione Agitatori Verticali', link: '/pdf/4_VERTICALI_IOM_GREC_2021_ITA.pdf' },
    { title: 'Manuale per la selezione degli agitatori', link: '/pdf/5.6_GREC_Manuale_per_la_selezione_degli_agitatori.pdf' },
    { title: 'Manuale installazione agitatori laterali', link: '/pdf/6.7_manuale_installazione_agitatori_laterali.pdf' },
    { title: 'Manuale installazione agitatori verticali', link: '/pdf/7.8_manuale_installazione_agitatori_verticali.pdf' },
    { title: 'Manuale estraibili GUTH', link: '/pdf/8.9GREC_Manuale_estraibili_GUTH.pdf' },
    { title: 'Manuale miscelatori statici', link: '/pdf/9.Static_mixer_IOM_GREC_2021_ITA.pdf' },
    { title: 'Foglio dati agitatori', link: '/pdf/11_GREC_Foglio_dati_agitatori.pdf' },
    { title: 'Brochure Agitatori per Fusti', link: '/pdf/12_13_GREC_Brochure_Agitatori_per_Fusti.pdf' },
    { title: 'Brochure Miscelatori Statici', link: '/pdf/13.14_GREC_Brochure_Miscelatori_Statici.pdf' },
    { title: 'Brochure Estraibili GUTH', link: '/pdf/14.15_GREC_Brochure_Estraibili_GUTH.pdf' },
    { title: 'Finiture Superficiali', link: '/pdf/15.16_GREC_Finiture_Superficiali.pdf' }
  ];
  
export default documents;  