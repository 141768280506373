export const getGeoLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          window._paq.push(['setCustomDimension', 1, 'latitude', latitude]);
          window._paq.push(['setCustomDimension', 2, 'longitude', longitude]);
          window._paq.push(['trackPageView']);
          console.log(`Geolocation success: Latitude ${latitude}, Longitude ${longitude}`);
        },
        error => {
          console.error('Geolocation error:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };