const data = [
  {
    id: 1,
    route: 'AVF',
    category: 'AGITATORI VERTICALI',
    img: 'https://media.sketchfab.com/models/6189f0a9560648029c16d45dff48caaf/thumbnails/bd4f94eec48349f59ea2b875356d9a79/71270a42880b4999a470406f442bbd91.jpeg',
    link: 'https://sketchfab.com/models/6189f0a9560648029c16d45dff48caaf/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0',
    schedatecnica: 'https://www.grec.it/images/schede-tecniche/Verticali/AVF.pdf',
    imgDetails: '/images/1.AVF.png',
    title: 'AVF',
    details: {
      header: {
        title: 'SERIE AVF',
        subtitle: 'Agitatori verticali con riduttore a VITE SENZA FINE',
        description: `La gamma di agitatori AVF rappresenta la miglior scelta per applicazioni in serbatoi di piccole dimensioni. Il riduttore a vite senza fine ha notevoli vantaggi: economico, leggero, compatto. Di contro ha efficienza inferiore a quella degli altri riduttori, quindi non risulta conveniente utilizzarlo per le potenze più elevate. L’albero dell’agitatore è direttamente inserito nel cavo del riduttore e fissato con vite di testa, rendendo inutile l’uso di un giunto intermedio.`,
        buttonLabel: 'Scarica la scheda tecnica'
      },
      seriesDetails: [
        {
          title: 'La versione –L',
          description: ' presenta una flangia aggiunta DN con tenuta a labbro inclusa. Serve ad evitare l’uscita di fumi o schizzi, e ad impedire che eventuali perdite di olio dal riduttore finiscano nel prodotto agitato.'
        },
        {
          title: 'La versione –S',
          description: ' presenta una lanterna con flangia aggiunta DN e tenuta meccanica inclusa. Serve contenere la pressione o il vuoto presenti all’interno del serbatoio. In questa versione è presente un giunto sotto flangia per poter spedire con la tenuta montata e l’albero smontato.'
        }
      ]
    },
    application: [
      {
        group: 'MINIERE E CERAMICA',
        items: ['Aggiunta smalti e coloranti', 'Preparazione soluzioni chimiche', 'Preparazione paste serigrafiche']
      },
      {
        group: 'ALIMENTI E BEVANDE',
        items: ['Preparazione e stoccaggio succhi di frutta e bevande']
      },
      {
        group: 'INDUSTRIA CHIMICA',
        items: ['Reattori chimici', 'Industria detergenza', 'Industria fertilizzanti', 'Fertilizzanti', 'Poliuretani', 'Stoccaggio prodotti sporchi o che tendono a stratificare']
      },
      {
        group: 'ENERGIA',
        items: ['Fasi di sintesi del biodiesel', 'Fermentatori per bioetanolo']
      },
      {
        group: 'INDUSTRIA FARMACEUTICA',
        items: ['Grossi fermentatori per preparazione principi attivi e antibiotici', 'Preparazione intermedi e prodotto finito', 'Creazione di emulsioni']
      },
      {
        group: 'TRATTAMENTO ACQUE',
        items: ['Neutralizzazione, Equalizzazione, Flocculazione', 'Preparazione Polielettrolita', 'Preparazione additivi chimici', 'Flash mixing', 'Preparazione latte di calce', 'Stoccaggio fanghi']
      },
      {
        group: 'INDUSTRIA DELLE VERNICI',
        items: ['Preparazione e stoccaggio resine e solventi']
      }
    ],
    description1: 'AGITATORI VERTICALI',
    description2: 'LENTI CON RIDUTTORE',
    description3: `AVF con riduttore a vite senza fine. <br/> <br/>
        Maggiormente impiegato in serbatoio chiusi fino a circa 15m3, con diversi sistemi di tenuta.<br/> <br/><u>Potenze fino a 4kW</u>.`
  },
  {
    id: 2,
    route: 'AVC',
    category: 'AGITATORI VERTICALI',
    img: `https://media.sketchfab.com/models/a60b65fd32cf48818e12c55bf999f58c/thumbnails/7cc9f5119e3c4e2a9e382371a8dd1304/7b0e1fe06e3e45a2aa6057871b7a4eed.jpeg`,
    link: "https://sketchfab.com/models/a60b65fd32cf48818e12c55bf999f58c/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    imgDetails: '/images/2.AVC.png',
    title: "AVC",
    details: {
      header: {
        title: 'SERIE AVC',
        subtitle: 'Agitatori verticali con riduttore a COPPIA CONICA',
        description: `La gamma di agitatori AVC la scelta per chi ha
                      necessità di personalizzazioni e applicazioni
                      impegnative, con serbatoi di media dimensione.
                      Il riduttore a coppia conica ha un efficienza
                      maggiore rispetto al fratello minore AVF, quindi
                      risulta conveniente utilizzarlo per le potenze più
                      elevate.
                      L’albero dell’agitatore è direttamente inserito nel
                      cavo del riduttore e fissato con vite di testa,
                      spesso fornito con albero di comando e
                      campana per alloggiamento tenuta meccanica. `,
        buttonLabel: 'Scarica la scheda tecnica'
      },
      seriesDetails: [
        {
          title: 'La versione –L ',
          description: ` presenta una flangia aggiunta DN con
                        tenuta a labbro inclusa. Serve a tenere tutte le parti
                        bagnate nel materiale richiesto, ad evitare l’uscita di
                        fumi o schizzi, e ad impedire che eventuali perdite di
                        olio dal riduttore finiscano nel prodotto agitato.`
        },
        {
          title: 'La versione –S',
          description: ` presenta una lanterna con flangia
                        aggiunta DN e tenuta meccanica inclusa. Serve
                        contenere la pressione o il vuoto presenti all’interno
                        del serbatoio.
                        In questa versione è presente un giunto sotto flangia
                        per poter spedire con la tenuta montata e l’albero
                        smontato.`
        }
      ]
    },
    application: [
      {
        group: 'MINIERE E CERAMICA',
        items: ['Aggiunta smalti e coloranti', 'Preparazione soluzioni chimiche', 'Preparazione paste serigrafiche']
      },
      {
        group: 'ALIMENTI E BEVANDE',
        items: ['Preparazione e stoccaggio succhi di frutta e bevande']
      },
      {
        group: 'INDUSTRIA CHIMICA',
        items: ['Reattori chimici', 'Industria detergenza', 'Industria fertilizzanti', 'Fertilizzanti', 'Poliuretani', 'Stoccaggio prodotti sporchi o che tendono a stratificare']
      },
      {
        group: 'ENERGIA',
        items: ['Fasi di sintesi del biodiesel', 'Fermentatori per bioetanolo']
      },
      {
        group: 'INDUSTRIA FARMACEUTICA',
        items: ['Grossi fermentatori per preparazione principi attivi e antibiotici', 'Preparazione intermedi e prodotto finito', 'Creazione di emulsioni']
      },
      {
        group: 'TRATTAMENTO ACQUE',
        items: ['Neutralizzazione, Equalizzazione, Flocculazione', 'Preparazione Polielettrolita', 'Preparazione additivi chimici', 'Flash mixing', 'Preparazione latte di calce', 'Stoccaggio fanghi']
      },
      {
        group: 'INDUSTRIA DELLE VERNICI',
        items: ['Preparazione e stoccaggio resine e solventi']
      }
    ],
    description1: "AGITATORI VERTICALI",
    description2: "LENTI CON RIDUTTORE",
    description3: `AVC riduttore a coppia conica.<br/> <br/>Trova impiego su serbatoi di grosse dimensioni quando è richiesto motore orizzontale.`
  },

  {
    id: 3,
    route: 'AVS',
    category: 'AGITATORI VERTICALI',
    img: `https://media.sketchfab.com/models/ff7fdd58674841a495726a371d9e2481/thumbnails/64e72724a4c040d5bedcba2424554e82/ad46c2639ef1476787d925ed1015d3b5.jpeg`,
    link: "https://sketchfab.com/models/ff7fdd58674841a495726a371d9e2481/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    imgDetails: '/images/3.AVS.png',
    title: "AVS",
    details: {
      header: {
        title: 'SERIE AVS',
        subtitle: ' Agitatori verticali con riduttore COASSIALE',
        description: `Il riduttore coassiale, selezionato con elevati
          fattori di servizio, presenta carcassa in ghisa
          rigida e cuscinetti rinforzati per assorbire sia
          la coppia trasmessa dal motore che il carico
          radiali restituito dalle giranti in rotazione,
          senza bisogno di lanterne aggiuntive.
          Il gruppo si estende con il motore in
          verticale ed è quindi preferito quando il tetto
          del serbatoio non presenta spazi liberi.
          L’albero dell’agitatore è fissato all’albero
          sporgente del riduttore con giunto rigido a
          manicotto. `,
        buttonLabel: 'Scarica la scheda tecnica AVS 0÷4'
      },
      header2: {
        title: 'SERIE AVS',
        subtitle: 'Agitatori verticali con riduttore EPICICLOIDALE ',
        description: `Il riduttore epicicloidale, selezionato con
          elevati fattori di servizio, presenta carcassa
          in ghisa rigida e cuscinetti rinforzati per
          assorbire sia la coppia trasmessa dal
          motore che il carico radiali restituito dalle
          giranti in rotazione, senza bisogno di
          lanterne aggiuntive.
          Il gruppo si estende con il motore in
          verticale ed è quindi preferito quando il tetto
          del serbatoio non presenta spazi liberi.
          L’albero dell’agitatore è fissato all’albero
          sporgente del riduttore con giunto rigido a
          flangia.`,
        buttonLabel: 'Scarica la scheda tecnica AVS 5÷11'
      },



    },
    application: [
      {
        group: 'MINIERE E CERAMICA',
        items: ['Aggiunta smalti e coloranti', 'Preparazione soluzioni chimiche', 'Preparazione paste serigrafiche']
      },
      {
        group: 'ALIMENTI E BEVANDE',
        items: ['Preparazione e stoccaggio succhi di frutta e bevande']
      },
      {
        group: 'INDUSTRIA CHIMICA',
        items: ['Reattori chimici', 'Industria detergenza', 'Industria fertilizzanti', 'Fertilizzanti', 'Poliuretani', 'Stoccaggio prodotti sporchi o che tendono a stratificare']
      },
      {
        group: 'ENERGIA',
        items: ['Fasi di sintesi del biodiesel', 'Fermentatori per bioetanolo']
      },
      {
        group: 'INDUSTRIA FARMACEUTICA',
        items: ['Grossi fermentatori per preparazione principi attivi e antibiotici', 'Preparazione intermedi e prodotto finito', 'Creazione di emulsioni']
      },
      {
        group: 'TRATTAMENTO ACQUE',
        items: ['Neutralizzazione, Equalizzazione, Flocculazione', 'Preparazione Polielettrolita', 'Preparazione additivi chimici', 'Flash mixing', 'Preparazione latte di calce', 'Stoccaggio fanghi']
      },
      {
        group: 'INDUSTRIA DELLE VERNICI',
        items: ['Preparazione e stoccaggio resine e solventi']
      }
    ],
    description1: "AGITATORI VERTICALI",
    description2: "LENTI CON RIDUTTORE",
    description3: `
      AVS riduttore coassiale a ingranaggi o epicicloidale.<br/><br/>
  Trova impiego maggiormente in vasche aperte. <br/><br/>
  Costruzione con motore in verticale e albero sporgente sotto il riduttore.<br/><br/>
   Le taglie maggiori sono disponibili anche con uscita rinforzata con cuscinetti molto distanziati per poter gestire alberi molto lunghi.
      `
  },


  {
    id: 4,
    route: 'AVL',
    category: 'AGITATORI VERTICALI',
    img: `https://media.sketchfab.com/models/8d25988dc3fb4747a88132c765d2f113/thumbnails/5b99a530a1d04749b0b072664d013eb3/fb1ee870a1bb4cf0b7b0b144c9736e5c.jpeg`,
    link: "https://sketchfab.com/models/8d25988dc3fb4747a88132c765d2f113/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    imgDetails: '/images/4.AVL.png',
    title: "AVL",
    details: {
      header: {
        title: 'SERIE AVL',
        subtitle: 'Agitatori verticali con riduttore ad ASSI PARALLELI',
        description: `Il riduttore ad assi paralleli, selezionato con
          elevati fattori di servizio, presenta carcassa in
          ghisa rigida e cuscinetti rinforzati per assorbire
          sia la coppia trasmessa dal motore che il carico
          radiali restituito dalle giranti in rotazione, senza
          bisogno di lanterne aggiuntive.
          Il gruppo si estende con il motore in verticale ed
          è quindi preferito quando il tetto del serbatoio
          non presenta spazi liberi.
          L’albero dell’agitatore è direttamente inserito nel
          cavo del riduttore e fissato con vite di testa,
          rendendo inutile l’uso di un giunto intermedio`,
        buttonLabel: 'Scarica la scheda tecnica'
      },
      seriesDetails: [
        {
          title: 'La versione –L',
          description: ` presenta una flangia aggiunta DN con
            tenuta a labbro inclusa. Serve a tenere tutte le parti
            bagnate nel materiale richiesto, ad evitare l’uscita di
            fumi o schizzi, e ad impedire che eventuali perdite di
            olio dal riduttore finiscano nel prodotto agitato `
        },
        {
          title: 'La versione –S',
          description: ` presenta una lanterna con flangia
            aggiunta DN e tenuta meccanica inclusa. Serve
            contenere la pressione o il vuoto presenti all’interno
            del serbatoio.
            In questa versione è presente un giunto sotto flangia
            per poter spedire con la tenuta montata e l’albero
            smontato.`,
        }
      ]
    },
    application: [
      {
        group: 'MINIERE E CERAMICA',
        items: ['Aggiunta smalti e coloranti', 'Preparazione soluzioni chimiche', 'Preparazione paste serigrafiche']
      },
      {
        group: 'ALIMENTI E BEVANDE',
        items: ['Preparazione e stoccaggio succhi di frutta e bevande']
      },
      {
        group: 'INDUSTRIA CHIMICA',
        items: ['Reattori chimici', 'Industria detergenza', 'Industria fertilizzanti', 'Fertilizzanti', 'Poliuretani', 'Stoccaggio prodotti sporchi o che tendono a stratificare']
      },
      {
        group: 'ENERGIA',
        items: ['Fasi di sintesi del biodiesel', 'Fermentatori per bioetanolo']
      },
      {
        group: 'INDUSTRIA FARMACEUTICA',
        items: ['Grossi fermentatori per preparazione principi attivi e antibiotici', 'Preparazione intermedi e prodotto finito', 'Creazione di emulsioni']
      },
      {
        group: 'TRATTAMENTO ACQUE',
        items: ['Neutralizzazione, Equalizzazione, Flocculazione', 'Preparazione Polielettrolita', 'Preparazione additivi chimici', 'Flash mixing', 'Preparazione latte di calce', 'Stoccaggio fanghi']
      },
      {
        group: 'INDUSTRIA DELLE VERNICI',
        items: ['Preparazione e stoccaggio resine e solventi']
      }
    ],
    description1: "AGITATORI VERTICALI",
    description2: "LENTI CON RIDUTTORE",
    description3: `
      
      AVL con riduttore ad assi paralleli.<br/><br/>
  Soluzione di alta qualità per ogni dimensione di serbatoio. <br/><br/>
  L’albero dell’agitatore si inserisce direttamente nell’albero cavo del riduttore. <br/><br/>
  Motore in posizione verticale.
      `
  },

  {
    id: 5,
    route: 'AV',
    category: 'AGITATORI VERTICALI',
    img: `https://media.sketchfab.com/models/e3b20b3dc1c649a9a997978e5c65600a/thumbnails/88211e61740e4e5f86fbf2bf65861b8c/7f729d68dd2c4684b0d9208324769240.jpeg`,
    link: "https://sketchfab.com/models/e3b20b3dc1c649a9a997978e5c65600a/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    imgDetails: '/images/5.AVh.png',
    title: "AV",
    details: {
      header: {
        title: 'SERIE AV',
        subtitle: 'Agitatori verticali veloci',
        description: `Gli agitatori verticali veloci, serie AV, sono la miglior soluzione per miscelazioni in serbatoi piccoli.
          Sono anche utilizzati per dissoluzioni o dispersioni di solidi in una massa liquida.
          Per le miscelazioni e le dissoluzioni si usano giranti marine (M), mentre per le dispersioni si preferisce l’uso di
          giranti a dente di sega o cowles (S).
          A parità di volume liquido, le potenze in gioco cambiano moltissimo a seconda se si tratti di miscelazione o
          dispersione. `,
        buttonLabel: 'Scarica la scheda tecnica'
      },
      seriesDetails: [
        {
          title: '',
          description: ` Il gruppo guida è costituito dal solo motore, che ha la
            flangia di connessione con risalto di centraggio,
            oppure si possono aggiungere altri componenti:`
        },
        {
          title: '-Flangia',
          description: ` piana in alluminio (serie AV0f) per avere la
            flangia di connessione piana invece che con risalto `,
        },
        {
          title: '-Lanterna',
          description: `da fusione in alluminio con cuscinetto
            reggispinta per poter fare alberi più lunghi (serie AV1 /
            AV2 / AV3 / AV4)`,
        },
        // {
        //   title: '',
        //   description: `Nel caso sia necessaria flangia INOX con sistema di
        //   tenuta, essa può essere a labbro (versione –L) o
        //   meccanica a secco (versione –S). `,
        // }
      ]
    },
    application: [
      {
        group: 'MINIERE E CERAMICA',
        items: ['Aggiunta smalti e coloranti', 'Preparazione soluzioni chimiche', 'Preparazione paste serigrafiche']
      },
      {
        group: 'ALIMENTI E BEVANDE',
        items: ['Preparazione e stoccaggio succhi di frutta e bevande']
      },
      {
        group: 'INDUSTRIA CHIMICA',
        items: ['Reattori chimici', 'Industria detergenza', 'Industria fertilizzanti', 'Fertilizzanti', 'Poliuretani', 'Stoccaggio prodotti sporchi o che tendono a stratificare']
      },
      {
        group: 'ENERGIA',
        items: ['Fasi di sintesi del biodiesel', 'Fermentatori per bioetanolo']
      },
      {
        group: 'INDUSTRIA FARMACEUTICA',
        items: ['Grossi fermentatori per preparazione principi attivi e antibiotici', 'Preparazione intermedi e prodotto finito', 'Creazione di emulsioni']
      },
      {
        group: 'TRATTAMENTO ACQUE',
        items: ['Neutralizzazione, Equalizzazione, Flocculazione', 'Preparazione Polielettrolita', 'Preparazione additivi chimici', 'Flash mixing', 'Preparazione latte di calce', 'Stoccaggio fanghi']
      },
      {
        group: 'INDUSTRIA DELLE VERNICI',
        items: ['Preparazione e stoccaggio resine e solventi']
      }
    ],
    description1: "AGITATORI VERTICALI",
    description2: "VELOCI SENZA RIDUTTORI",
    description3: `AVS riduttore coassiale a ingranaggi o epicicloidale. Trova impiego maggiormente in vasche aperte.   <br/> <br/>
      Costruzione con motore in verticale e albero sporgente sotto il riduttore.  <br/> <br/>
      Le taglie maggiori sono disponibili anche con uscita rinforzata con cuscinetti molto distanziati per poter gestire alberi molto lunghi.`
  },


  {
    id: 6,
    route: 'TD',
    category: 'AGITATORI VERTICALI',
    img: ``,
    link: "",
    title: "TD",
    imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "AGITATORI VERTICALI",
    description2: "TURBODISPERSORI",
    description3: ``
  },


  {
    id: 7,
    route: 'ALF',
    category: 'AGITATORI LATERALI',
    img: ``,
    link: "",
    title: "ALF",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "AGITATORI LATERALI",
    description2: "LENTI CON RIDUTTORE",
    description3: ``
  },
  {
    id: 8,
    route: 'ALC',
    category: 'AGITATORI LATERALI',
    img: ``,
    link: "",
    title: "ALC",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "AGITATORI LATERALI",
    description2: "LENTI CON RIDUTTORE",
    description3: ``
  },
  {
    id: 9,
    route: 'ALS',
    category: 'AGITATORI LATERALI',
    img: ``,
    link: "",
    title: "ALS",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "AGITATORI LATERALI",
    description2: "LENTI CON RIDUTTORE",
    description3: ``
  },
  {
    id: 10,
    route: 'AL',
    category: 'AGITATORI LATERALI',
    img: ``,
    link: "",
    title: "AL",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "AGITATORI LATERALI",
    description2: "DIRETTI",
    description3: ``
  },

  {
    id: 11,
    route: 'GUTH-RA',
    category: '',
    category: 'AGITATORI PORTATILI',
    img: ``,
    link: "",
    title: "GUTH RA",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "AGITATORI PORTATILI",
    description2: "LATERALI PER CANTINA",
    description3: ``
  },
  {
    id: 12,
    route: '/',
    category: '',
    category: 'AGITATORI PORTATILI',
    img: ``,
    link: "",
    title: "/",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "AGITATORI PORTATILI",
    description2: "PORTATILI PER INDUSTRIA CHIMICA",
    description3: ``
  },



  {
    id: 13,
    route: 'TM',
    category: 'IN LINEA',
    img: ``,
    link: "",
    title: "TM",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "IN LINEA",
    description2: "MISCELATORI STATICI",
    description3: ``
  },

  {
    id: 14,
    route: 'BM',
    category: 'IN LINEA',
    img: ``,
    link: "",
    title: "BM",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "IN LINEA",
    description2: "MISCELATORI STATICI",
    description3: ``
  },

  {
    id: 15,
    route: 'VM',
    category: 'IN LINEA',
    img: ``,
    link: "",
    title: "VM",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "IN LINEA",
    description2: "MISCELATORI STATICI",
    description3: ``
  },

  {
    id: 16,
    route: 'HY',
    category: 'IN LINEA',
    img: ``,
    link: "",
    title: "HY",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "IN LINEA",
    description2: "MISCELATORI STATICI",
    description3: ``
  },

  {
    id: 17,
    route: '',
    category: 'IN LINEA',
    img: ``,
    link: "",
    title: "",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "IN LINEA",
    description2: "MISCELATORI DINAMICI",
    description3: ``
  },


  {
    id: 18,
    route: '//',
    category: 'IN LINEA',
    img: ``,
    link: "",
    title: "/",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "IN LINEA",
    description2: "MISCELATORI DINAMICI",
    description3: ``
  },



  {
    id: 19,
    route: 'T',
    category: 'GIRANTI',
    img: ``,
    link: "",
    title: "T",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "GIRANTI",
    description2: "ALTA PORTATA",
    description3: ``
  },

  {
    id: 20,
    route: 'B',
    category: 'GIRANTI',
    img: ``,
    link: "",
    title: "B",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "GIRANTI",
    description2: "ALTA PORTATA",
    description3: ``
  },

  {
    id: 21,
    route: 'F',
    category: 'GIRANTI',
    img: ``,
    link: "",
    title: "F",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "GIRANTI",
    description2: "ALTA PORTATA",
    description3: ``
  },




  {
    id: 22,
    route: 'M',
    category: 'GIRANTI',
    img: ``,
    link: "",
    title: "M",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "GIRANTI",
    description2: "ALTA PREVALENZA",
    description3: ``
  },


  {
    id: 23,
    route: 'G',
    category: 'GIRANTI',
    img: ``,
    link: "",
    title: "G",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "GIRANTI",
    description2: "ALTA PREVALENZA",
    description3: ``
  },

  {
    id: 24,
    route: 'C',
    category: 'GIRANTI',
    img: ``,
    link: "",
    title: "C",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "GIRANTI",
    description2: "ALTA PREVALENZA",
    description3: ``
  },



  {
    id: 25,
    route: 'TD',
    category: 'GIRANTI',
    img: ``,
    link: "",
    title: "TD",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "GIRANTI",
    description2: "IBRIDE",
    description3: ``
  },

  {
    id: 26,
    route: 'E',
    category: 'GIRANTI',
    img: ``,
    link: "",
    title: "E",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "GIRANTI",
    description2: "IBRIDE",
    description3: ``
  },

  {
    id: 27,
    route: 'P',
    category: 'GIRANTI',
    img: ``,
    link: "",
    title: "P",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "GIRANTI",
    description2: "IBRIDE",
    description3: ``
  },

  {
    id: 28,
    route: 'R',
    category: 'GIRANTI',
    img: ``,
    link: "",
    title: "R",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "GIRANTI",
    description2: "ALTO TAGLIO",
    description3: ``
  },
  {
    id: 29,
    route: 'H',
    category: 'GIRANTI',
    img: ``,
    link: "",
    title: "H",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "GIRANTI",
    description2: "ALTO TAGLIO",
    description3: ``
  },
  {
    id: 30,
    route: 'S',
    category: 'GIRANTI',
    img: ``,
    link: "",
    title: "S",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "GIRANTI",
    description2: "ALTO TAGLIO",
    description3: ``
  },
];


export default data;