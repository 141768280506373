import React, { useEffect, useState, useRef, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import {
  IoMenuOutline, IoArrowBackOutline, IoArrowForwardOutline, IoCloseOutline, IoVolumeMute, IoVolumeHigh, IoPause, IoPlay, IoFastFoodOutline,
  IoConstructOutline,
  IoFlaskOutline,
  IoFlashOutline,
  IoMedkitOutline,
  IoWaterOutline,
  IoWater,
  IoColorPaletteOutline,
  IoClose,
  IoSend,
  IoSearch,
  IoLogoFacebook,
  IoLogoLinkedin,
  IoGlobeOutline
} from "react-icons/io5";

import { MdOutlineFastfood } from "react-icons/md";

import './scss/styles.scss'
import { initMatomo } from './components/matomo';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet';

import data from "./noSQL/data"
import documents from './noSQL/documents';
import images from './noSQL/images'
import cards from './noSQL/cards'
import Disclaimer from './pages/Disclaimer';

import Lavora from "./pages/Lavora"




function App() {

  useEffect(() => {
    initMatomo();
  }, []);

  let lastScrollY = window.pageYOffset;

  const handleScroll = () => {
    const currentScrollY = window.pageYOffset;
    if (currentScrollY > lastScrollY) {
      document.body.classList.add('scroll-down');
      document.body.classList.remove('scroll-up');
    } else {
      document.body.classList.add('scroll-up');
      document.body.classList.remove('scroll-down');
    }
    lastScrollY = currentScrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    
    <Router>
      <ScrollToTop />
      <Navbar />
      <Cookie />
      <div className='body--max--control' >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/chi-siamo" element={<AboutUs />} />
          <Route path="/prodotti" element={<Product data={data} />} />
          <Route path="/prodotti/:route" element={<ProductDetails />} />
          <Route path="/applicazioni" element={<Applicazioni />} />
          <Route path="/download" element={<Download />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contatti" element={<Contatti />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/lavora-con-noi" element={<Lavora/>} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};


export default App;



// // // // // // // // // // // // // // // // // // // // // // // // 
// Homepage
// // // // // // // // // // // // // // // // // // // // // // // // 


const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.3,
    },
  }),
};

function Home() {

  


  return (
    <>
      <div className="App">
        <br /> <br /> <br />
        <div className="video-container">
          <video className="video" autoPlay muted loop playsInline>
            <source src="/animation2.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="video-button">
            <h1>Agitatori <span>|</span> Dispersori <br/>Sistemi di Tenuta <span>|</span>  Giranti <span>|</span>  Miscelatori</h1>
          <Link to="/prodotti" > <button >
     I Nostri Prodotti
      </button></Link> 
      <Link to="/chi-siamo" >    <button className='button-variant-2' >
  Chi Siamo
      </button></Link>
          </div>
          <div className="video-social">

          <a target="_blank" href="https://www.linkedin.com/company/grec-srl/"><IoLogoLinkedin size={35} /></a>
          <a target="_blank" href="https://www.facebook.com/grecsrl"><IoLogoFacebook size={35}/></a>
          </div>
        </div>



<div className='Grec-subtitle-container'>
      
          <p>
            GREC srl è una realtà italiana specializzata nella selezione, progettazione e costruzione di <Link to="/prodotti">agitatori industriali</Link> per liquidi.
          </p>
        </div>

{/* 
        <div className='Grec--title-catory-container'>
      
          <p>
            I nostri prodotti
          </p>
        </div> */}

        

 {/* <hr style={{ opacity: 0.2, margin: '25px 5% 25px' }} /> */}
 {/* <img src="/catalogo-generale.png"  /> */}



 <div className='Grec--card--groups'>
      {cards.map((card, index) => (
        <motion.div
          className='Grec--card'
          key={index}
          custom={index}
          initial="hidden"
          animate="visible"
          variants={cardVariants}
        >
          <h2>{card.title}</h2>
          <p>{card.description}</p>
          <IoArrowForwardOutline className='card-nav' size={42} />
        </motion.div>
      ))}
    </div>


        
     


     {/* <img src="/img.png"  /> */}


{/* <div className='Grec--flex-img'>

<div className='Grec-subtitle-container'>
      
      <p>
       Campi d'applicazione
      </p>
 
 <div className='application-sml'  >
 {sectors.map((sector, index) => (
              <div key={index} className="sector--" >
                <span >{sector.icon}</span>
                <p>{sector.title}</p>
              </div>
            ))}
 </div>
    </div>

<img src="/Laterali_cantine.jpg"  />
  
</div>



<hr style={{ opacity: 0.2, margin: '25px 5% 25px' }} /> */}




{/* <hr style={{ opacity: 0.2, margin: '25px 5% 25px' }} />
<div className='Grec--flex-container' >  
<div className='Grec-subtitle-container'>
      
      <p>
       Scarica il nostro Catalogo Generale
      </p>
      <button>Download</button>
    </div>



    <div className='Grec-subtitle-container'>
      <h2>Contattaci</h2>
          <p>
          Per qualsiasi dubbio o informazione non esitare a contattarci
          </p>
          <button>Clicca qui</button>
        </div>


        </div> */}
    {/* <img src="/catalogo-generale.png"  /> */}
  


    <hr style={{ opacity: 0.2, margin: '25px 5% 25px' }} />

      </div>
    </>
  );
}
// // // // // // // // // // // // // // // // // // // // // // // // 
// Chi Siamo
// // // // // // // // // // // // // // // // // // // // // // // // 
const AboutUs = () => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [showPlayIcon, setShowPlayIcon] = useState(false);
  const [showPauseIcon, setShowPauseIcon] = useState(false);

  const handleVideoClick = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setShowPlayIcon(true);
      setIsPaused(false);
      setTimeout(() => setShowPlayIcon(false), 1000);
    } else {
      videoRef.current.pause();
      setShowPauseIcon(true);
      setIsPaused(true);
      setTimeout(() => setShowPauseIcon(false), 1000);
    }
  };

  const handleMuteClick = () => {
    setIsMuted(prevState => !prevState);
    videoRef.current.muted = !videoRef.current.muted;
  };

 

  return (
    <>
      <section className='page--container' >
        <div className='Grec-title-container'>
          <h1>Chi Siamo</h1>
          <p>
            GREC srl è una realtà italiana specializzata nella selezione, progettazione e costruzione di <Link to="/prodotti">agitatori industriali</Link> per liquidi.
          </p>
        </div>

        <div className="video-container-about" onClick={handleVideoClick}>
          <video
            className="video video-about"
            autoPlay
            muted={isMuted}
            playsInline
            ref={videoRef}
          >
            <source src="/CorporateGrec.webm" type="video/webm" />
            Your browser does not support the video tag.
          </video>
          <button className="mute-button" onClick={handleMuteClick}>
            {isMuted ? <IoVolumeMute /> : <IoVolumeHigh />}
          </button>
          {showPlayIcon && <IoPlay className="video-icon play-icon" />}
          {showPauseIcon && <IoPause className="video-icon pause-icon" />}
        </div>
    
      </section>
<div className='Grec-content-container'>
<h2>La nostra Filosofia</h2>
<p>

Nei processi industriali, non esiste uno standard consolidato per il dimensionamento degli agitatori nelle diverse applicazioni. 
<br/>Ogni costruttore propone la propria soluzione basata sull'esperienza.
</p>

<p>A seconda dei casi, il parametro principale per la selezione può essere la potenza assorbita, la portata generata, la velocità periferica o una combinazione di queste. In funzione del tipo di girante, il comportamento dell’agitatore può variare, anche se uno di questi parametri coincide.

</p>
</div>
<hr style={{ opacity: 0.2, margin: '25px 16% 25px' }} />
{/* <div  className='img-container'  >

<img  src="/GRC3.png"  />
</div> */}

<div className='Grec-content-container'>
<p>

I tecnici di GREC srl hanno messo a punto delle procedure di selezione validate da molteplici installazioni, grazie alla loro vasta esperienza, condivisa con realtà leader a livello mondiale per la ricerca e sviluppo.
</p>

<p>La filosofia di selezione è volta a realizzare la macchina più semplice ed economica, cercando di trasformare l’energia fornita dall’azionamento nel modo più efficace all’interno del liquido, per il raggiungimento dell’obiettivo dell’agitazione con il minimo consumo.
</p>
</div>

{/* <div  className='img-container'  >

<img  src="/GRC2.png"  />
</div> */}
<hr style={{ opacity: 0.2, margin: '25px 16% 25px' }} />

<div className='Grec-content-container'>
<p>

GREC Srl non realizza complesse soluzioni meccaniche basate sull’esperienza dell’utilizzatore finale, ma è invece molto disponibile a studiare con lo stesso una possibile soluzione alternativa, di minor complessità e costo, con l’utilizzo delle proprie giranti.
</p>

<p>Per applicazioni non conosciute, GREC srl si affida anche alla collaborazione con una rete di costruttori internazionali specializzati in differenti settori.

</p>
</div>


<div  className='img-container'  >

<img  src="/GRC1.png"  />
</div>


<div className='Grec-content-container'>
<p>

Grazie alla struttura snella e alla perfetta conoscenza del dimensionamento meccanico degli agitatori, nei campi di maggior esperienza GREC Srl è in grado di fornire il miglior rapporto prestazioni/prezzo presente sul mercato.
</p>


</div>

      <br /><br />
    </>
  );
};
// // // // // // // // // // // // // // // // // // // // // // // // 
// Prodotti
// // // // // // // // // // // // // // // // // // // // // // // // 
const Product = ({ data }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleFilterClick = (category) => {
    setSelectedCategory((prevCategory) => (prevCategory === category ? null : category));
  };

  const filteredData = selectedCategory 
    ? data.filter(item => item.category === selectedCategory) 
    : data;


    const cardVariants = {
      hidden: { opacity: 0, y: 20 },
      visible: (i) => ({
        opacity: 1,
        y: 0,
        transition: {
          delay: i * 0.1,
        },
      }),
    };

  return (
    <>
      <section className='page--container'>
        <div className='Grec-title-container'>
          <h1>Agitatori Miscelatori Dispersori</h1>
          <p>
            Scopri le nostre soluzioni avanzate, progettate per ottimizzare l'efficienza nei tuoi processi produttivi.
          </p>
        </div>
        <div className='Grec--button-filter'>
          {['AGITATORI VERTICALI', 'AGITATORI LATERALI', 'AGITATORI PORTATILI', 'IN LINEA', 'GIRANTI'].map(category => (
            <button
              key={category}
              className={selectedCategory === category ? 'active' : ''}
              onClick={() => handleFilterClick(category)}
            >
              {category}
            </button>
          ))}
        </div>
        <hr style={{ opacity: 0.2, margin: '25px 5% 25px' }} />
        <div className="Grec--card-container">
        {filteredData.map((item, index) => (
        <motion.div
          key={item.id}
          className="Grec--machine-card"
          initial="hidden"
          animate="visible"
          custom={index}
          variants={cardVariants}
        >
          <Link to={`/prodotti/${item.route}`}>
            <div>
              <div className='card-img-container'>
                <img src={item.img} alt={item.title} />
              </div>
              <h2>{item.title}</h2>
              <p>{item.description2}</p>
              <span>{item.description1}</span>
            </div>
          </Link>
        </motion.div>
      ))}
        </div>
      </section>
      <br /><br /><br />
    </>
  );
};

// // // // // // // // // // // // // // // // // // // // // // // // 
// Scheda Prodotto
// // // // // // // // // // // // // // // // // // // // // // // // 

const ProductDetails = () => {
  const { route } = useParams();
  const navigate = useNavigate();
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const productIndex = data.findIndex(item => item.route === route);
  const product = data[productIndex];
  
  if (productIndex === -1) {
    return <h2>Prodotto non trovato</h2>;
  }

  const handleBackClick = () => {
    const prevIndex = productIndex > 0 ? productIndex - 1 : data.length - 1;
    navigate(`/prodotti/${data[prevIndex].route}`);
  };

  const handleForwardClick = () => {
    const nextIndex = productIndex < data.length - 1 ? productIndex + 1 : 0;
    navigate(`/prodotti/${data[nextIndex].route}`);
  };



  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
        type: 'spring',
        stiffness: 50,
      },
    }),
  };

  const sameCategoryProducts = data.filter(item => item.category === product.category && item.route !== route);

  return (
    <>
      <section className='Product-Body'>
        <br />    <br />    <br />    <br />     <br />
        <div className='Grec--machine--header'>
          <h2>
            <Link to="/prodotti">PRODOTTI</Link> / {product.title}
          </h2>
          <button>Richiedi un preventivo</button>
        </div>
        <hr style={{ opacity: 0.2, margin: '25px 8% 25px' }} />
        <div className='Grec--machine--main'>
          <div className='Grec--machine--info'>
            <h1>{product.title}</h1>
            <p>{product.description1}</p>
            <p>{product.description2}</p>
            <p className='Grec--machine--description' dangerouslySetInnerHTML={{ __html: product.description3 }}></p>
          </div>
          <div className='Grec--machine-control' onClick={handleBackClick}>
            <IoArrowBackOutline className='Grec--machine-icon' />
          </div>
          <div className='Grec--machine-control' onClick={handleForwardClick}>
            <IoArrowForwardOutline className='Grec--machine-icon' />
          </div>
          <div className="sketchfab-embed-wrapper rotating">
            <div id="overlay">
              <iframe
                title={product.title}
                src={product.link}
                frameBorder="0"
                allowFullScreen
                style={{ width: '100%', height: '680px' }}
              ></iframe>
            </div>
          </div>
        </div>
        <hr style={{ opacity: 0.2, margin: '25px 8% 25px' }} />

        <div className="Grec--machine--details-container">
          <div className="Grec--machine--details">
            <img src={product.imgDetails} alt={product.title} />
            <div className='Grec--machine--content'>
              <h2>{product.details.header.title}</h2>
              <h3>{product.details.header.subtitle}</h3>
              <p>{product.details.header.description}</p>
            </div>
            <button>
              <a href={product.schedatecnica} download>
                {product.details.header.buttonLabel}
              </a>
            </button>
          </div>

          {product.details.header2 &&  (
            <div className="Grec--machine--details details-2 ">
              <div className='Grec--machine--content content-2'>
                <h2>{product.details.header2.title}</h2>
                <h3>{product.details.header2.subtitle}</h3>
                <p>{product.details.header2.description}</p>
              </div>
              <button>
                <a href={product.schedatecnica2} download>
                  {product.details.header2.buttonLabel}
                </a>
              </button>
            </div>
          )}

          {!product.details.header2 && product.details.seriesDetails && (
            <div className="Grec--machine--series-details">
              {product.details.seriesDetails.map((detail, index) => (
                <div className='Grec--machine--series-desc' key={index}>
                  
                  <p><strong>{detail.title} </strong>{detail.description}</p>
                </div>
              ))}
            </div>
          )}
        </div>

        <hr style={{ opacity: 0.2, margin: '25px 8% 25px' }} />

        <div className='machine--body'>
          <h2>CAMPI DI APPLICAZIONE</h2>
          <div ref={ref} className="machine--card--aplication--group">
            {product.application.map((group, index) => (
              <motion.div
                className="machine--card--aplication"
                key={index}
                initial="hidden"
                animate={controls}
                custom={index}
                variants={cardVariants}
              >
                <h3>{group.group}</h3>
                {group.items.map((item, idx) => (
                  <p key={idx}>{item}</p>
                ))}
              </motion.div>
            ))}
          </div>
        </div>
      </section>


      <hr style={{ opacity: 0.2, margin: '25px 8% 25px' }} />
       
          {/* <div className="Grec--card-container">
            {sameCategoryProducts.map((item, index) => (
             <motion.div
             key={item.id}
             className="Grec--machine-card"
             initial="hidden"
             animate="visible"
             custom={index}
             variants={cardVariants}
           >
             <Link to={`/prodotti/${item.route}`}>
               <div>
                 <div className='card-img-container'>
                   <img src={item.img} alt={item.title} />
                 </div>
                 <h2>{item.title}</h2>
                 <p>{item.description2}</p>
                 <span>{item.description1}</span>
               </div>
             </Link>
           </motion.div>
            ))}
          </div> */}
     
    </>
  );
};
// // // // // // // // // // // // // // // // // // // // // // // // 
// Applicazioni
// // // // // // // // // // // // // // // // // // // // // // // // 


const sectors = [
  {
    id: 'alimenti-bevande',
    title: 'ALIMENTI E BEVANDE',
    href: '',
    img: 'https://www.grec.it/images/mercati/alimentare.jpg',
    icon: <IoFastFoodOutline size={45} />,
    details: {
      heading: 'INDUSTRIA ALIMENTARE',
      info: [
        {
          title: 'Stoccaggio prodotti enologici',
          links: ['ALF', 'ALS', 'AL'],
        },
        {
          title: 'Stoccaggio olio, acidi grassi',
          links: ['ALC'],
        },
        {
          title: 'Preparazione e stoccaggio succhi di frutta e bevande',
          links: ['AGITATORI VERTICALI'],
        },
        {
          title: 'Preparazione soluzioni zuccherine e salamoia',
          links: ['AV', 'AL'],
        },
        {
          title: 'Preparazione miscele ed emulsioni',
          links: ['TD', 'COWLES'],
        },
      ],
    },
  },
  {
    id: 'miniera-ceramica',
    title: 'MINIERE E CERAMICA',
    href: '',
    img: 'https://www.grec.it/images/mercati/ceramica.jpg',
    icon: <IoConstructOutline size={45} />,

    details: {
      heading: 'INDUSTRIA DELLA CERAMICA',
      info: [
        {
          title: 'Vasche di stoccaggio acque di processo o lavaggio',
          links: ['AVS', 'AVL']
        },
        {
          title: 'Omogeneizzazione barbottina',
          links: ['AVS', 'AVSh', 'AVL']
        },
        {
          title: 'Aggiunta smalti e coloranti',
          links: ['AGITATORI VERTICALI']
        },
        {
          title: 'Preparazione soluzioni chimiche',
          links: ['AGITATORI VERTICALI']
        },
        {
          title: 'Preparazione paste serigrafiche',
          links: ['AGITATORI VERTICALI']
        }
      ]
    }
  },
  {
    id: 'chimica',
    title: 'INDUSTRIA CHIMICA',
    href: '',
    img: 'https://www.grec.it/images/mercati/chimica.jpg',
    icon: <IoFlaskOutline size={45} />,
    details: {
      heading: 'INDUSTRIA CHIMICA',
      info: [
        {
          title: 'Reattori chimici',
          links: ['AGITATORI VERTICALI']
        },
        {
          title: 'Industria detergenza',
          links: ['AGITATORI VERTICALI']
        },
        {
          title: 'Industria fertilizzanti',
          links: ['AGITATORI VERTICALI']
        },
        {
          title: 'Fertilizzanti',
          links: ['AGITATORI VERTICALI']
        },
        {
          title: 'Poliuretani',
          links: ['AGITATORI VERTICALI']
        },
        {
          title: 'Stoccaggio prodotti sporchi o che tendono a stratificare',
          links: ['AGITATORI VERTICALI']
        },
        {
          title: 'Scambio termico',
          links: ['In funzione della geometria del serbatoio']
        },
        {
          title: 'Preparazione miscele',
          links: ['In funzione della geometria del serbatoio']
        }
      ]
    }
  },
  {
    id: 'energia',
    title: 'ENERGIA',
    href: '',
    img: 'https://www.grec.it/images/mercati/energia.jpg',
    icon: <IoFlashOutline size={45} />,
    details: {
      heading: 'ENERGIA',
      info: [
        {
          title: 'Digestori Biogas',
          links: ['AVS', 'ALC']
        },
        {
          title: 'Fasi di sintesi del biodiesel',
          links: ['AGITATORI VERTICALI']
        },
        {
          title: 'Fermentatori per bioetanolo',
          links: ['AGITATORI VERTICALI']
        },
        {
          title: 'Desolforazione fumi (FGD)',
          links: ['ALC']
        }
      ]
    }
  },
  {
    id: 'farmaceutica',
    title: 'INDUSTRIA FARMACEUTICA',
    href: '',
    img: 'https://www.grec.it/images/mercati/farmacia.jpg',
    icon: <IoMedkitOutline size={45} />,
    details: {
      heading: 'INDUSTRIA FARMACEUTICA',
      info: [
        {
          title: 'Grossi fermentatori per preparazione principi attivi e antibiotici',
          links: ['AGITATORI VERTICALI']
        },
        {
          title: 'Preparazione intermedi e prodotto finito',
          links: ['AGITATORI VERTICALI E MAGNETICI']
        },
        {
          title: 'Creazione di emulsioni',
          links: ['AGITATORI VERTICALI', 'TURBODISPERSORI']
        }
      ]
    }
  },
  {

    id: 'oli-gas',
    title: 'OLI & GAS',
    href: '',
    img: 'https://www.grec.it/images/mercati/petrolio.jpg',
    icon: <IoWater size={45} />,
    details: {
      heading: 'OLI & GAS',
      info: [
        {
          title: 'Additivazione gasoli',
          links: ['ALC']
        },
        {
          title: 'Miscelazione e stoccaggio di diversi gasoli',
          links: ['ALC']
        },
        {
          title: 'Miscelazione in linea di diversi tagli di prodotto',
          links: ['AGITATORI DINAMICI IN LINEA', 'MISCELATORI STATICI']
        }
      ]
    }
  },
  {
    id: 'acque',
    title: 'TRATTAMENTO ACQUE',
    href: '',
    img: 'https://www.grec.it/images/mercati/acque.jpg',
    icon: <IoWaterOutline size={45} />,
    details: {
      heading: 'TRATTAMENTO DELLE ACQUE',
      info: [
        {
          title: 'Neutralizzazione, Equalizzazione, Flocculazione',
          links: ['AGITATORI VERTICALI']
        },
        {
          title: 'Preparazione Polielettrolita',
          links: ['AGITATORI VERTICALI']
        },
        {
          title: 'Preparazione additivi chimici',
          links: ['AGITATORI VERTICALI']
        },
        {
          title: 'Flash mixing',
          links: ['AGITATORI VERTICALI']
        },
        {
          title: 'Preparazione latte di calce',
          links: ['AGITATORI VERTICALI']
        },
        {
          title: 'Stoccaggio fanghi',
          links: ['AGITATORI VERTICALI']
        }
      ]
    }
  },
  {
    id: 'vernici',
    title: 'INDUSTRIA DELLE VERNICI',
    href: '',
    img: 'https://www.grec.it/images/mercati/vernice.jpg',
    icon: <IoColorPaletteOutline size={45} />,
    details: {
      heading: 'INDUSTRIA DELLE VERNICI',
      info: [
        {
          title: 'Preparazione e stoccaggio resine e solventi',
          links: ['AGITATORI VERTICALI']
        },
        {
          title: 'Dispersione pigmenti',
          links: ['DISPERSORI COWLES']
        },
        {
          title: 'Preparatori in linea',
          links: ['MISCELATORI IN LINEA']
        }
      ]
    }
  }
];


function Applicazioni() {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedSector, setSelectedSector] = useState(null);
  const openModal = (sector) => {
    setSelectedSector(sector);
    setModalIsOpen(true);
    window.location.hash = sector.id;
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedSector(null);
    window.location.hash = '';
  };
  const handleHashChange = useCallback(() => {
    const sectorId = window.location.hash.slice(1);
    if (sectorId) {
      const sector = sectors.find(s => s.id === sectorId);
      if (sector && (!modalIsOpen || (selectedSector && selectedSector.id !== sectorId))) {
        openModal(sector);
      }
    } else {
      closeModal();
    }
  }, [sectors, modalIsOpen, selectedSector]);
  useEffect(() => {
    handleHashChange();
    window.addEventListener('hashchange', handleHashChange);
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [handleHashChange]);

  const generateDetailsComponent = (details) => (
    <div className='sector-detail'>
      <h2>{details.heading}</h2>
      <div className='sector-detail-info'>
        {details.info.map((item, index) => (
          <div key={index}>
            <p className='p-sector-detail'>
              <span>{item.title}</span>
              <span>
                {item.links.map((link, idx) => (
                  <a key={idx} href="#">{link}</a>
                ))}
              </span>
            </p>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <>
      <section className='page--container' >
        <div className='Grec-title-container'>
          <h1>Applicazioni</h1>
          <p>
            Scopri le nostre soluzioni per diversi settori industriali.
          </p>
        </div>
        <div className="sector-list">
          <div className="sector-container">
            {sectors.map((sector, index) => (


              <div 
              
              key={index} className="sector-item" onClick={() => openModal(sector)}>
                <div className="icon-container">{sector.icon}</div>
                <p>{sector.title}</p>
              </div>
            ))}
          </div>
        </div>
        {modalIsOpen && (
          <div className="modal-application  ">
            <div className="modal-content-application ">
              <button onClick={closeModal} className="close-button-application">
                <IoClose />
              </button>
              {selectedSector && (
                <>
                  {/* <h2>{selectedSector.title}</h2> */}
                  <div    >
                    <div className="image-container">
                      <img src={selectedSector.img} alt={selectedSector.title} className="modal-img" />
                      <div className="image-gradient"></div>
                    </div>
                    {generateDetailsComponent(selectedSector.details)}
                    {/* <hr style={{ opacity: 0.2, margin: '25px 2% 25px' }} /> */}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        <br /><br />

        
      </section>
    </>
  );
}
// // // // // // // // // // // // // // // // // // // // // // // // 
// Download
// // // // // // // // // // // // // // // // // // // // // // // // 




function Download() {



  return (
    <section className='page--container' >
      <div className='Grec-title-container'>
        <h1>Download</h1>
        <p>
          Accedi ai nostri manuali e brochure tecniche per informazioni dettagliate sui nostri prodotti e soluzioni di miscelazione.
        </p>
      </div>


      <div className="document-list">
        <div className="documents-container">
          {documents.map((document, index) => (
            <div key={index} className="document-item">
              <a href={document.link} target="_blank" rel="noopener noreferrer">
                {document.title}
              </a>
            </div>
          ))}
        </div>
      </div>
      <br /><br /><br /><br /><br /><br />
    </section>
  );
}
// // // // // // // // // // // // // // // // // // // // // // // // 
// Gallery
// // // // // // // // // // // // // // // // // // // // // // // // 
function Gallery() {
 

  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const handleCloseClick = () => {
    setSelectedImageIndex(null);
  };

  const handlePrevClick = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
  };

  const handleNextClick = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
  };

  return (
    <>
      <section className='page--container'>
        <div className='Grec-title-container'>
          <h1>Gallery</h1>
          <p className="gallery-copy">Esplora le nostre collezioni fotografiche che catturano l'essenza dei nostri prodotti e progetti.</p>
        </div>

        <div className="gallery-container">
          <div className="image-grid">
            {images.map((image, index) => (
              <div key={index} className="image-item" onClick={() => handleImageClick(index)}>
                <img src={image.src} alt={`img-${index}`} />
              </div>
            ))}
          </div>

          {selectedImageIndex !== null && (
            <div className="modal gallery-modal-control">
               <IoCloseOutline className='Grec--navbar--icon close-button' onClick={handleCloseClick} />
           
              <img className="modal-content" src={images[selectedImageIndex].src} alt="Selected" />
            
              <div className=' prev' onClick={handlePrevClick}>
                <IoArrowBackOutline className='Grec--machine-icon' />
              </div>
              <div className=' next' onClick={handleNextClick}>
                <IoArrowForwardOutline className='Grec--machine-icon' />
              </div>
              </div>
           
          )}
        </div>
      </section>
    </>
  );
}

// // // // // // // // // // // // // // // // // // // // // // // // 
// Contatti
// // // // // // // // // // // // // // // // // // // // // // // // 

function Contatti() {
  return (
    <>
      <section className='page--container' >
        <div className='Grec-title-container'>
          <h1>Contattaci</h1>
          <p>
          Hai qualche richiesta relativa ai nostri prodotti?<br/> Se non riesci a trovare sul sito le informazioni che stai cercando, puoi contattarci compilando il form oppure inviandoci una mail.
         
         <br/><br/>
         <u>Risponderemo a tutte le richieste.</u>
          </p>
          
        </div>

    





        <div className='Grec--contact-container'>
{/* <form  className='Grec--contact-form'  >
<input  className='input-full'  placeholder='Nome e Cognome / Società'  />
<div  className='Grec--contact-head'  >

  <input  className='input-full'    placeholder='Email'  />
  <input   className='input-full'   placeholder='Telefono'  />
  </div>
 
<div className='Grec--contact-body'>


<textarea   placeholder='Messaggio'     />
  <br/>  <br/>
  <input name="tos"  type="radio"  />

<label for="tos"  >Accetto i <Link to="/disclaimer"> termini di servizio.</Link>  </label>
<br/>  <br/>
</div>
<button> 
  <span>Invia</span>   <span></span> < IoSend /> </button>


</form> */}



</div>


<br/>
<br/>
<hr style={{ opacity: 0.2, margin: '35px 16% 35px' }} />
<br/>
<div className='Grec-title-container'>
          <h1>Dove Trovarci</h1>
          <p>
        Viale Lombardia 16/N - 20081 Cassinetta di Lugagnano (MI) - ITALY
          </p>
          
        </div>

        <div  className='Maps' >

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2800.5753042714837!2d8.897829651539222!3d45.41790304444903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786efe5ba77b4f1%3A0x7d075a63cfcda283!2sViale%20Lombardia%2C%2016%2C%2020081%20Cassinetta%20di%20Lugagnano%20MI!5e0!3m2!1sit!2sit!4v1618835177570!5m2!1sit!2sit"
            style={{ border: 'none', width: '100%', height: '100%' }}
            title="Grec Maps"
          />
        </div> 



        <br />

        <br />
      </section>
    </>

  );
}


// // // // // // // // // // // // // // // // // // // // // // // // 
// Componenti
// // // // // // // // // // // // // // // // // // // // // // // // 







// // // // // // // // // // // // // // // // // // // // // // // // //  Footer
function Footer() {
  return (
    <section className='Grec--footer' >

      <div className='footer-main'>
        <div className='footer-logo-container'  >
          
        <Link to="/" >
          <img src="/logo-white.png" />
        </Link>


        <div>
           <a target="_blank" href="https://www.linkedin.com/company/grec-srl/"><IoLogoLinkedin size={35} /></a>
          <a target="_blank" href="https://www.facebook.com/grecsrl"><IoLogoFacebook size={35}/></a></div>
          </div>
        <div>
          <p>Home</p>
          <p>Chi Siamo</p>
          <p>Prodotti</p>
          <p>Download</p>
        </div>
        <div>
          <p>Applicazioni</p>
          <p>Gallery</p>
          <p>Contatti</p>
        </div>
        <div>
          <p>Social Policy</p>
          <p>Cookie Policy</p>
          <p>Privacy Policy</p>
        </div>
        <div className='footer-contact'  >
        <p>+ 39 02 36645050</p>
          <p>info@grec.it</p>
         
        </div>
      </div>
      <div className='footer-down'>
        <p>GREC Srl - Viale Lombardia 16/N - 20081 Cassinetta di Lugagnano (MI) - ITALY</p>
        <hr style={{ opacity: 0.2, margin: '5px' }} />
        <div className='flex'>
          <p>Copyright © 2016 GREC. All Rights Reserved.</p>


          <p>Designed by <span className='ShneBreit'  >Firewall S.r.l.</span>  </p>
        </div>

      </div>


    </section>
  );
}

// // // // // // // // // // // // // // // // // // // // // // // // //  Cookies
function Cookie() {

  const [isVisible, setIsVisible] = useState(true);

  const handleAcceptAll = () => {
    setIsVisible(false);
  };

  const handleRejectAll = () => {
    // logica rifiuto cookie
    setIsVisible(false);
  };


  return (
    <>
      {isVisible && (
        <section className='Grec--cookie'>
          <div>
            <h2>🍪 Sì, usiamo i cookie</h2>
          </div>
          <div>
            <p>
              Questo sito utilizza i cookie per funzionalità essenziali e analisi. Puoi cambiare le impostazioni in qualsiasi momento o accettare quelle predefinite. Chiudi questo banner per continuare con solo i cookie essenziali.
              Leggi di più su questo nella nostra informativa sulla <u>privacy e sui cookie</u>.
            </p>
          </div>
          <hr />
          <div className='Grec--cookie--buttons'>
            <div>
              <button onClick={handleAcceptAll}>
                Accetta tutto
              </button>
              <button onClick={handleRejectAll}>
                Rifiuta tutto
              </button>
            </div>
            <button>Gestisci preferenze</button>
          </div>
        </section>
      )}
    </>
  );
}


// // // // // // // // // // // // // // // // // // // // // // // // //  Navbar
function Navbar() {
  const location = useLocation();
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isSearchClosing, setIsSearchClosing] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const searchInputRef = useRef(null);

  const handleFirstButtonClick = () => {
    setIsDivVisible(true);
    setIsClosing(false);
    setIsSearchVisible(false); 
  };

  const handleSecondButtonClick = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsDivVisible(false);
    }, 500);
  };

  const handleSearchClick = () => {
    if (isSearchVisible) {
      setIsSearchClosing(true);
      setTimeout(() => {
        setIsSearchVisible(false);
        setIsSearchClosing(false);
      }, 500);
    } else {
      setIsSearchVisible(true);
      setIsDivVisible(false); 
    }
    setSearchQuery('');
    setSearchResults([]);
  };

  useEffect(() => {
    if (isSearchVisible) {
      searchInputRef.current.focus();
    }
  }, [isSearchVisible]);

  useEffect(() => {
    if (searchQuery.length > 0) {
      const dataResults = data.filter(item => item.title.toLowerCase().includes(searchQuery.toLowerCase()));
      const documentResults = documents.filter(doc => doc.title.toLowerCase().includes(searchQuery.toLowerCase()));
      setSearchResults([...dataResults, ...documentResults]);
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  return (
    <>
      <nav className="Grec--navbar">
        <div>
          <IoMenuOutline className='Grec--navbar--icon' onClick={handleFirstButtonClick} />
        </div>
        <Link onClick={handleSecondButtonClick} to="/">
          <img src="https://www.grec.it/images/logo-red.png" alt="Grec logo" />
        </Link>
        <div>
          <IoSearch className='Grec--navbar--icon' onClick={handleSearchClick} 
          // style={{ marginRight : '5px' }} 
          />
          {/* <IoGlobeOutline className='Grec--navbar--icon'  /> */}
     
        </div>
      </nav>
      {isSearchVisible && (
        <div className={`search-container ${isSearchClosing ? 'slide-up' : ''}`}>
          <input
            type="text"
            className="search-input"
            placeholder="Cerca..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            ref={searchInputRef}
          />
          {searchResults.length > 0 && (
            <ul className="search-results">
              {searchResults.map((result, index) => (
                <li key={index}>
                  {'route' in result ? (
                    <Link to={`/prodotti/${result.route}`} onClick={handleSearchClick}>
                  <p> <strong>Prodotti/</strong> <span>{result.title} </span>      </p>  
                    </Link>
                  ) : (
                    <a href={result.link} target="_blank" rel="noopener noreferrer" onClick={handleSearchClick}>
                      <p> <strong>Download/</strong> <span>{result.title} </span>   </p>
                    </a>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
      {isDivVisible && (
        <>
          <div className={`Grec--navbar--menu ${isClosing ? 'slide-out' : 'slide-in'}`}>
            <IoCloseOutline className='Grec--navbar--icon' onClick={handleSecondButtonClick} />
            <div>
              <Link onClick={handleSecondButtonClick} to="/">
                <p className={location.pathname === '/' ? 'Active' : ''}><span>HOME</span></p>
              </Link>
              <Link to="/chi-siamo" onClick={handleSecondButtonClick}>
                <p className={location.pathname === '/chi-siamo' ? 'Active' : ''}>CHI SIAMO</p>
              </Link>
              <Link onClick={handleSecondButtonClick} to="/prodotti">
                <p className={location.pathname.startsWith('/prodotti') ? 'Active' : ''}>PRODOTTI</p>
              </Link>
              <Link onClick={handleSecondButtonClick} to="/download">
                <p className={location.pathname.startsWith('/download') ? 'Active' : ''}>DOWNLOAD</p>
              </Link>
              <Link onClick={handleSecondButtonClick} to="/applicazioni">
                <p className={location.pathname.startsWith('/applicazioni') ? 'Active' : ''}>APPLICAZIONI</p>
              </Link>
              <Link onClick={handleSecondButtonClick} to="/gallery">
                <p className={location.pathname.startsWith('/gallery') ? 'Active' : ''}>GALLERY</p>
              </Link>
              <Link onClick={handleSecondButtonClick} to="/contatti">
                <p className={location.pathname.startsWith('/contatti') ? 'Active' : ''}>CONTATTI</p>
              </Link>
              <Link onClick={handleSecondButtonClick} to="/disclaimer">
                <p className={location.pathname.startsWith('/disclaimer') ? 'Active' : ''}>DISCLAIMER</p>
              </Link>
              <Link onClick={handleSecondButtonClick} to="/lavora-con-noi">
                <p className={location.pathname.startsWith('/lavora-con-noi') ? 'Active' : ''}>LAVORA CON NOI</p>
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  );
}