import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// // // // // // // // // // // // // // // // // // // // // // // // 
// Disclaimer
// // // // // // // // // // // // // // // // // // // // // // // // 

function Disclaimer() {

    const [isFixed, setIsFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const navbarHeight = document.querySelector('.Grec--navbar').offsetHeight;
            if (window.scrollY > navbarHeight) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const handleButtonClick = (anchor) => {
        const element = document.querySelector(anchor);
        if (element) {
            const offsetPosition = element.getBoundingClientRect().top + window.pageYOffset - 150;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };
    return (
        <>
            <section className='page--container' >
                <div className='Grec-title-container'>
                    <h1>Disclaimer</h1>
                    <p>
                        Le modalità di gestione del sito in riferimento al trattamento dei dati personali degli utenti che lo consultano.
                    </p>
                </div>


                <div className={`button-group ${isFixed ? 'fixed' : ''}`}>
                    <div className='discaimer-content'    >

                        <button onClick={() => handleButtonClick('#privacy-policy')}>Privacy Policy</button>
                        <button onClick={() => handleButtonClick('#cookie-policy')}>Cookie Policy</button>
                        <button onClick={() => handleButtonClick('#social-media-policy')}>Social Media Policy</button>

                    </div>
                </div>

                <br />

                <div className='discaimer-content-body'   >

                    <h2 id="privacy-policy" >

                        PRIVACY POLICY

                    </h2>
                    <p>
                        In questa pagina si descrivono le modalità di gestione del sito in riferimento al trattamento dei dati personali degli utenti che lo consultano.
                        <br /><br />
                        Si tratta di un’informativa che è resa ai sensi dell’art. 13 del d.lgs. n. 196/2003 – Codice in materia di protezione dei dati personali - a coloro che interagiscono con i servizi web della società Grec S.r.l. accessibili per via telematica a partire dall’indirizzo:
                    </p>

                    <h3>https://www.grec.it</h3>

                    <p>
                        corrispondente alla pagina iniziale del sito istituzionale della società Grec S.r.l. qui di seguito identificata.
                        <br /><br />
                        L’informativa è resa solo per il sito www.grec.it e non per altri siti eventualmente consultabili tramite link.
                        <br /><br />
                        L’informativa individua i requisiti per la raccolta di dati personali on-line e, in particolare, le modalità, i tempi e la natura delle informazioni che i titolari del trattamento devono fornire agli utenti quando questi si collegano a pagine web, indipendentemente dagli scopi del collegamento. La invitiamo quindi a prendere visione della nostra Politica di Privacy, di seguito illustrata.
                        <br /><br />
                        La Politica e gli Standard di Privacy a cui Grec S.r.l. fa riferimento per la protezione dei dati personali sono fondati sui principi di responsabilità, trasparenza, pertinenza, finalità dell’utilizzo, verificabilità e sicurezza.


                    </p>

                    <h2>IL “TITOLARE” DEL TRATTAMENTO</h2>

                    <p>

                        A seguito della consultazione di questo sito possono essere trattati dati relativi a persone identificate o identificabili.
                        <br /><br />
                        Il Titolare del loro trattamento è società Grec S.r.l. , che ha sede in Binasco (MI) - CAP 20082 – Via Copernico n° 3 – P.IVA |C.F. 06972530965 – Reg. Imprese MI 06972530965 – R.E.A. MI 1926397 – Cap. soc. i.v. € 90.000,00.
                        <br /><br />

                    </p>

                    <h2>LUOGO DI TRATTAMENTO DEI DATI</h2>

                    <p>

                        I trattamenti connessi ai servizi web di questo sito hanno luogo presso la predetta sede della società Grec S.r.l. e sono curati dal personale incaricato del trattamento, oppure da eventuali incaricati di occasionali operazioni di manutenzione. Nel caso in cui terze parti che svolgono attività di supporto di qualsiasi tipo per l’erogazione di servizi da parte della Scrivente, in relazione ai quali eseguono operazioni di trattamento di dati personali, sono da quest’ultime designate Responsabili del trattamento e sono contrattualmente vincolate al rispetto delle misure per la sicurezza e la riservatezza dei trattamenti.
                        <br /><br />
                        I Suoi dati non saranno in alcun modo diffusi.
                        <br /><br />
                        Per completezza va ricordato che in alcuni casi, l’Autorità (Garante o delegati) può richiedere notizie e informazioni ai sensi dell’art 157 del d.lgs. n. 196/2003, ai fini del controllo sul trattamento dei dati personali. In questi casi la risposta è obbligatoria a pena di sanzione amministrativa.


                    </p>

                    <h2>TIPI DI DATI TRATTATI</h2>

                    <h3>
                        Dati di navigazione
                    </h3>

                    <p>

                        I sistemi informatici e le procedure software preposte al funzionamento di questo sito web acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet.
                        <br /><br />
                        Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti.
                        <br /><br />
                        In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati che si connettono al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all’ambiente informatico dell’utente.
                        <br /><br />
                        Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull’uso del sito e per controllarne il corretto funzionamento e vengono cancellati immediatamente dopo l’elaborazione.
                        <br /><br />
                        I dati potrebbero essere utilizzati per l’accertamento di responsabilità in caso di ipotetici reati informatici ai danni del sito: salva questa eventualità, allo stato i dati sui contatti web non persistono per più di sette giorni.

                    </p>


                    <h3>Cookies</h3>
                    <p>

                        Per l’utilizzo dei cookies si rimanda all’apposita sezione “Cookie Policy” dove è resa un’idonea informativa.
                    </p>

                    <h3>
                        Social Media Policy
                    </h3>

                    <p>
                        Per l’utilizzo dei social network, si rimanda all’apposita sezione “Social Media Policy” dove è resa un’idonea informativa estesa.
                    </p>


                    <h2>
                        Dati forniti volontariamente dall’utente

                    </h2>


                    <p>
                        L’eventuale invio di dati forniti volontariamente dall’utente attraverso il form presente nella sezione CONTATTI (per cui è stata resa un’idonea informativa) oppure attraverso l’iscrizione al sito presente nell’area REGISTRATI (per cui è stata resa un’idonea informativa), comporta l’acquisizione dei dati inseriti dall’utente nonché di eventuali altre informazioni contenute nel messaggio; tali dati personali saranno utilizzati al solo fine di rispondere alla richiesta inoltrata. I dati raccolti sono trattati con modalità cartacee, automatizzate e telematiche e con logiche strettamente correlate alle finalità del trattamento.
                    </p>


                    <h2>MODALITA’ DEL TRATTAMENTO DEI DATI FORNITI VOLONTARIAMENTE DALL’UTENTE E POLITICA DI PROTEZIONE DATI</h2>


                    <p>

                        I dati personali sono trattati con strumenti automatizzati per il tempo strettamente necessario a conseguire gli scopi per cui sono stati raccolti.
                        <br /><br />
                        Specifiche misure di sicurezza sono osservate per prevenire la perdita dei dati, usi illeciti o non corretti ed accesi non autorizzati. La società Grec S.r.l. utilizza architetture e tecnologie “sicure” per proteggere i dati personali contro l’indebita divulgazione, alterazione od uso improprio. Le protezioni attivate nei confronti dei dati personali si propongono, in particolare, di ridurre al minimo i rischi di distruzione o di perdita, anche accidentale, dei dati, di accessi non autorizzati o di trattamento non consentito o non conforme alle finalità della raccolta. Dette misure di sicurezza rispondono ovviamente ai requisiti minimi indicati dal Legislatore (Disciplinare Tecnico in materia di misure minime di sicurezza di cui agli artt. da 33 a 36 del D.lgs. n. 196/03). Vengono condotte attività periodiche di “Analisi dei rischi” per verificare l’aderenza agli Standard definiti ed eventualmente adottare nuove misure di sicurezza a seguito di cambiamenti organizzativi ed innovazioni tecnologiche o cambiamenti nella tipologia di dati raccolti. Le misure di sicurezza sono costantemente controllate e periodicamente verificate. I soggetti cui si riferiscono i dati personali hanno il diritto in qualunque momento di ottenere la conferma dell’esistenza o meno dei medesimi dati e di conoscerne il contenuto e l’origine, verificarne l’esattezza o chiederne l’integrazione o l’aggiornamento, oppure la rettificazione (art. 7 del D.lgs. n. 196/03). Ai sensi del medesimo articolo l’interessato ha il diritto di chiedere la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, nonché di opporsi in ogni caso, per motivi legittimi, al loro trattamento.
                        <br /><br />
                        Le richieste vanno rivolte all’indirizzo di posta elettronica privacy@grec.it .
                        <br /><br />
                        Per Sua comodità riproduciamo integralmente l’articolo suddetto:

                    </p>


                    <ul>

                        <li>L’interessato ha diritto di ottenere la conferma dell’esistenza o meno di dati personali che lo riguardano, anche se non ancora registrati, e la loro comunicazione in forma intelligibile.</li>
                        <li>
                            L’ interessato ha diritto di ottenere l’indicazione:
                            <ul>
                                <li> Dell’origine dei dati personali;</li>
                                <li> delle finalità e modalità del trattamento;</li>
                                <li>della logica applicata in caso di trattamento effettuato con l’ausilio di strumenti elettronici;</li>
                                <li>degli estremi identificativi del titolare, dei responsabili e del rappresentante designato ai sensi dell’articolo 5, comma 2;</li>
                                <li>dei soggetti o delle categorie di soggetti ai quali i dati personali possono essere comunicati o che possono venirne a conoscenza in qualità di rappresentante designato nel territorio dello Stato di responsabili o incaricati. L’ interessato ha diritto di ottenere:</li>
                                <li>l’aggiornamento, la rettificazione ovvero, quando vi ha interesse, l’integrazione dei dati;</li>
                                <li>la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, compresi quelli di cui non è necessaria la conservazione in relazione agli scopi per i quali i dati sono stati raccolti o successivamente trattati;</li>
                                <li>l’attestazione che le operazioni di cui alle lettere a) e b) sono state portate a conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai quali i dati sono stati comunicati o diffusi, eccettuato il caso in cui tale adempimento si rivela impossibile o comporta un impiego di mezzi manifestamente sproporzionato rispetto al diritto tutelato.</li>




                            </ul>

                        </li>

                        <li>
                            L’ interessato ha diritto di opporsi, in tutto o in parte:
                            <ul>
                                <li>per motivi legittimi al trattamento dai dati personali che lo riguardano, ancorché pertinenti allo scopo della raccolta;</li>
                                <li>al trattamento di dati personali che lo riguardano a fini di invio di materiale pubblicitario o di vendita diretta o per il compimento di ricerche di mercato o di comunicazione commerciale.</li>
                            </ul>

                        </li>

                    </ul>

                </div>





                <br id="cookie-policy" />



                <div className='discaimer-content-body'   >
                    <h2>
                        COOKIE POLICY
                    </h2>

                    <h3>
                        La Sua Privacy
                    </h3>

                    <p>
                        Grec S.r.l. si impegna a proteggere le Sue informazioni personali e questo documento si propone di aiutarLa a capire quali sono le informazioni che potremmo raccogliere su di Lei e come le usiamo.

                    </p>


                    <h3>

                        Che cosa sono i Cookies e come li utilizziamo
                    </h3>

                    <p>


                        Un cookie è un semplice file di testo che viene memorizzato sul Suo computer o dispositivo mobile dal server di un sito web e che solo quel server sarà in grado di recuperare o leggerne il contenuto. Esso contiene alcune informazioni anonime e permette al sito web di ricordare alcuni dati.
                    </p>


                    <h3>Perché usiamo i Cookies</h3>

                    <p>

                        Siamo sempre alla ricerca di modi per migliorare il nostro sito internet e per rendere più facile la conoscenza dei nostri servizi e delle nostre proposte.
                        <br /><br />
                        Abbiamo classificato i cookies più comunemente usati in base al loro tipo di utilizzo e li classifichiamo come: essenziali, funzionali, statistici, comportamentali, di terza parte, di sessione e permanenti.
                        <br /><br />
                        Solo alcuni di essi vengono utilizzati in questo sito.
                    </p>

                    <h3>

                        Come fare a controllare e a gestire i Cookies
                    </h3>


                    <p>

                        La sezione di "Guida" del Suo browser dovrebbe essere in grado di aiutarLa con la gestione delle impostazioni dei cookies. Se non desidera ricevere cookies, è possibile modificare le impostazioni del browser in modo che avvisi su quando essi vengono inviati. È anche possibile eliminare in qualsiasi momento i cookies che sono già stati memorizzati, tramite le impostazioni del browser. È possibile scegliere se accettare o meno i cookies che vengono impostati dal nostro sito nella pagina di impostazioni dei cookies. La informiamo che la maggior parte del nostro sito web lavora senza l’utilizzo di cookies, ma che se si sceglie di disabilitare alcuni di essi, si perdono caratteristiche e funzionalità.

                    </p>



                    <h2>
                        COME ABILITARE O DISABILITARE I COOKIE SUL PROPRIO BROWSER
                    </h2>



                    <h4>Google Chrome</h4>
                    <hr />
                    <ul>

                        <li>
                            Cliccare sull'icona della chiave inglese sulla barra del browser
                        </li>


                        <li>
                            Selezionare "Impostazioni"
                        </li>

                        <li>
                            Cliccare su "Mostra impostazioni avanzate"
                        </li>

                        <li>
                            Nella sezione “Privacy”, cliccare sul pulsante "Impostazioni contenuti"
                        </li>

                        <li>
                            Per abilitare i cookie, nella sezione "Cookie", selezionare "Consenti il salvataggio dei dati in locale". In questo modo verranno abilitati sia i cookie first-party che quelli di terzi. Per abilitare solo i cookie first-party, invece, attiva la voce "Blocca cookie di terze parti e dati dei siti".
                        </li>

                        <li>
                            Per disabilitare completamente i cookie, selezionare "Impedisci ai siti di impostare dati"
                        </li>

                        <li>
                            Vi sono diversi livelli di abilitazione dei cookie in Chrome. Per maggiori informazioni sull'impostazione dei cookie su Chrome, fare riferimento alla seguente pagina:
                        </li>

                    </ul>

                    <a href="https://support.google.com/chrome/answer/95647?hl=it-IT&hlrm=fr&hlrm=en"  >https://support.google.com/chrome/answer/95647?hl=it-IT&hlrm=fr&hlrm=en</a>




                    <h4>Microsoft Internet Explorer</h4>
                    <hr />

                    <ul>


                        <li>
                            Cliccare su "Strumenti" nella parte alta della finestra del browser e clicca su "Opzioni Internet"
                        </li>


                        <li>
                            Spostarsi sulla linguetta "Privacy"
                        </li>

                        <li>
                            Per abilitare i cookie: Muovere il selettore su "Media" o inferiore
                        </li>

                        <li>
                            Per disabilitare tutti i cookie: Muovere il selettore completamente in altro
                        </li>


                        <li>
                            Vi sono diversi livelli di abilitazione dei cookie su Internet Explorer. Per maggiori informazioni sull'impostazione dei cookie su Internet Explorer, fare riferimento alla seguente pagina di Microsoft:
                        </li>

                    </ul>

                    <a href="http://windows.microsoft.com/en-GB/windows-vista/Block-or-allow-cookies">http://windows.microsoft.com/en-GB/windows-vista/Block-or-allow-cookies</a>



                    <h4>Mozilla Firefox</h4>
                    <hr />


                    <ul>
                        <li>
                            Cliccare su "Opzioni" dal menù del browser
                        </li>

                        <li>
                            Selezionare il pannello "Privacy"
                        </li>

                        <li>
                            Per abilitare i cookie: Selezionare la voce "Accetta i cookie dai siti"
                        </li>

                        <li>
                            Per disabilitare i cookie: Deselezionare la voce "Accetta i cookie dai siti"
                        </li>

                        <li>
                            Vi sono diversi livelli di abilitazione dei cookie su Internet Explorer. Per maggiori informazioni sull'impostazione dei cookie su Internet Explorer, fare riferimento alla seguente pagina di Mozilla:
                        </li>
                        <a href="http://support.mozilla.org/en-US/kb/Enabling%20and%20disabling%20cookies"  >http://support.mozilla.org/en-US/kb/Enabling%20and%20disabling%20cookies</a>

                    </ul>

                    <h4>Opera</h4>
                    <hr />


                    <ul>

                        <li>
                            Cliccare su "Impostazioni" dal menu del browser
                        </li>

                        <li>
                            Selezionare "Impostazioni veloci"
                        </li>


                        <li>
                            Per abilitare i cookies: seleziona "Attiva Cookie"
                        </li>

                        <li>
                            Per disabilitare i cookie: deseleziona "Attiva Cookie"
                        </li>


                        <li>
                            Vi sono diversi livelli di abilitazione dei cookie su Internet Explorer. Per maggiori informazioni sull'impostazione dei cookie su Opera, fare riferimento alla seguente pagina di Opera:
                        </li>
                    </ul>


                    <a href="http://www.opera.com/browser/tutorials/security/privacy/"   >http://www.opera.com/browser/tutorials/security/privacy/</a>



                    <h4>Safari OSX</h4>
                    <hr />



                    <a href="http://support.apple.com/kb/PH11913" >http://support.apple.com/kb/PH11913</a>


                    <ul>

                        <li>
                            Cliccare su "Safari" dalla barra dei menu e seleziona "Preferenze"
                        </li>



                        <li>
                            Cliccare su "Sicurezza"
                        </li>



                        <li>
                            Per abilitare i cookie: Nella sezione "Accetta i cookie" selezionare "Solo per il sito che sto navigando"
                        </li>


                        <li>
                            Per disabilitare i cookie: Nella sezione "Accetta i cookie" selezionare "Mai"
                        </li>

                        <li>
                            Vi sono diversi livelli di abilitazione dei cookie su Internet Explorer. Per maggiori informazioni sull'impostazione dei cookie su Opera, fare riferimento alla seguente pagina di Apple:
                        </li>




                    </ul>


                    <h4>Per tutti i browser</h4>
                    <hr />

                    <p>Fare riferimento all'aiuto in linea del browser che si sta usando.
                        Se sta usando dispositivi diversi tra loro per visualizzare e accedere al Sito (per esempio, computer, smartphone, tablet, ecc.), deve assicurarsi che ciascun browser su ciascun dispositivo sia regolato per riflettere le proprie preferenze relative ai cookie. Per eliminare i cookie dal browser Internet del proprio smartphone/tablet è necessario fare riferimento al manuale d’uso del dispositivo.</p>


                    <h2>DEVE ACCETTARE I COOKIE PER POTER VISITARE IL SITO?</h2>
                    <p>

                        No, non necessariamente. È possibile disabilitare i cookie, compresi quelli utilizzati dal nostro sito web, semplicemente disattivando la funzione dei cookie nel proprio browser o negando il consenso all’informativa. Tuttavia, alcune parti del sito, così come di altre pagine web, potrebbero non funzionare correttamente una volta disattivata tale funzione. Ad esempio in caso di accesso all’area riservata, il sito web non potrà procedere in assenza dell’autenticazione fornita dai cookie.
                        <br /><br />
                        <strong>Dove può trovare maggiori informazioni sui cookies</strong>
                        <br />
                        <h3>Garante Privacy</h3>
                        <a href="http://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/2145979"   >http://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/2145979</a>
                        <br /><br />
                        <h3>Organizzazioni indipendenti</h3>
                        <br />
                        <a href="http://www.allaboutcookies.org/"  >http://www.allaboutcookies.org/</a>


                    </p>

                    <h3>
                        I diversi tipi di Cookies
                    </h3>
                    <h2>
                        ESSENZIALI
                    </h2>

                    <p>
                        I cookies “essenziali” sono i cookies strettamente necessari per consentire apposite funzionalità richieste.
                        <br /><br />
                        Ad esempio, i siti di commercio elettronico (in cui il consumatore ha un carrello della spesa), non funzionano senza l'uso di cookies e sarebbe condizionata la funzionalità del sito, come aggiungere articoli al carrello e procedere attraverso il check-out.
                        <br /><br />
                        I cookies che sono essenziali per l'utilizzo di un sito web sono esenti ai sensi della direttiva UE, ma noi desideriamo che Lei abbia una completa informazione a riguardo.
                        <br /><br />
                        <strong>Questo sito <u>NON</u>utilizza cookies definiti come Essenziali.</strong>

                    </p>

                    <h2>FUNZIONALI</h2>

                    <p>
                        I cookies "funzionali" vengono utilizzati per fornire servizi o per ricordare le impostazioni per migliorare la Sua visita e l'esperienza nel nostro sito.
                        <br />
                        <strong>Questo sito utilizza cookies definiti come Funzionali.</strong>
                    </p>


                    <h2>STATISTICI</h2>
                    <p>I cookies "statistici" raccolgono informazioni su come utilizza il nostro sito web: per esempio: quali sono le pagine visitate e gli eventuali errori che si possono incontrare. Questi cookies sono completamente anonimi e vengono utilizzati solo per aiutarci a migliorare come funziona il nostro sito e capire gli interessi dei nostri utenti.</p>



                    <p>

                        <strong>Utilizziamo i cookies statistici per...</strong>
                        <br /><br />
                        Fornire statistiche su come viene utilizzato il nostro sito web;
                        <br /><br />
                        aiutarci a migliorare il sito, misurando gli eventuali errori che si verificano.
                        <br /><br />
                        <strong>I cookies che abbiamo definito come cookies "statistici" NON saranno utilizzati per ...</strong>
                        <br /><br />
                        Raccogliere informazioni che potrebbero essere utilizzate per pubblicizzare prodotti o servizi per voi su altri siti web.
                        <br /><br />
                        In alcuni casi, alcuni di questi cookies sono gestiti per noi da terzi, ma non consentiamo a questi di utilizzare i cookies per scopi diversi da quelli sopra elencati.
                        <br /><br />
                        <strong>Questo sito <u>NON</u>utilizza cookies definiti come Statistici.</strong>
                        <br />
                        <h3>DI MONITORAGGIO O DI TERZA PARTE</h3>
                        I cookies “di monitoraggio” sono legati ai servizi forniti da terze parti e monitorano l'efficacia della pubblicità. Questo sito utilizza cookie di terze parti.
                        <br /><br />
                        <strong>Questo sito utilizza Cookie di terze parti.</strong>
                        <br />
                        <h3>COMPORTAMENTALI</h3>
                        <br />
                        I cookies “comportamentali” sono i cookies utilizzati per raccogliere dati sulle Sue abitudini di navigazione e sulle Sue preferenze, effettuando profilazioni e/o personalizzazioni a scopo pubblicitario, per il compimento di ricerche di mercato o di vendita a distanza: si parla in questi casi di pubblicità comportamentale e di pubblicità mirata. Questi trattamenti sono valutati con molta prudenza dal legislatore europeo e italiano, che ha imposto regole molto restrittive. Infatti, la UE ha deciso di imporre agli operatori di internet di richiedere il consenso preventivo dell’utente, prima di poter usare cookies per fare pubblicità comportamentale o comunque per finalità ulteriori rispetto a quelle strettamente necessarie per fornire il servizio richiesto dall’interessato.

                        <br /><br />
                        <strong>Questo sito <strong>NON</strong> utilizza cookies definiti come Comportamentali.</strong>

                        <br />
                        <h3>DI SESSIONE E COOKIES PERMANENTI</h3>
                        <br />
                        La distinzione tra cookies di sessione e cookies permanenti può essere effettuata in ragione della loro persistenza sul terminale dell’utente. Quelli che si cancellano automaticamente alla fine di ogni navigazione, si chiamano “session cookies” e sono utilizzati da questo sito; se viceversa essi hanno una vita più lunga, si parla di cookies permanenti.
                        <br />
                        <br />
                        Ecco una lista di cookies utilizzati su questo sito:
                        <br /><br />
                        <img style={{ width: '100%', borderRadius: '5px' }} src="https://www.grec.it/images/cookie.png" />
                    </p>

                </div>


                <br id="social-media-policy" />

                <div className='discaimer-content-body'   >

                    <h2>SOCIAL MEDIA POLICY</h2>
                    <p>
                        Grec S.r.l. (di seguito anche: "Società"), con sede a Binasco, in Via Copernico, 3 ‐ 20082 ‐ Italia, Titolare ex art. 28 d.lgs. 196/2003 del trattamento dei Suoi dati personali La rimanda alla presente Social Media Policy. In relazione ai suddetti trattamenti il Titolare fornisce, tra l’altro, le seguenti informazioni.
                        <br /><br />
                        La presente Social Media Policy (“Social Media Policy”) deve intendersi quale allegato al documento Privacy Policy consultabile, in un documento separato, nella sezione dedicata pubblicata all’indirizzo internet https://www.grec.it .
                        <br /><br />
                        Detta Social Media Policy ha come finalità quella di descrivere le norme di condotta che gli utenti dovrebbero rispettare nell’utilizzo del sito web e negli spazi attivati da Grec S.r.l. sulle piattaforme tecnologiche sociali.

                    </p>

                    <h3>
                        Finalità
                    </h3>

                    <p>

                        Grec S.r.l. utilizza il sito e le piattaforme tecnologiche sociali di seguito indicate (“Social Media”) con finalità di interesse generale per comunicare con gli utenti attraverso la rete, dare accesso a servizi erogati e promuovere le proprie attività. Le pagine/canali sociali sono inoltre utilizzati dalla Titolare per favorire la partecipazione, il confronto, il dialogo con gli utenti in un’ottica di trasparenza e di condivisione.
                    </p>

                    <h3>Contenuti</h3>

                    <p>
                        Grec S.r.l. offre, tramite link, porte di accesso ai vari Social Media (che possono includere senza limitazione, tra gli altri, Facebook, Linkedin, Google+, ecc.), detti servizi, forniscono aree di commento, bacheche e forum pubblici. I contenuti vengono pubblicati dagli utenti e possono comprendere: comunicazioni sulle attività e servizi erogati dalla Titolare, informazioni relative a progetti in corso d’opera, iniziative sociali, immagini, e video relativi ad eventi organizzati dalla Titolare stessa. Sulle varie pagine/canali di dette piattaforme sono attivati servizi di risposta a richieste di informazioni. Vengono inoltre raccolti commenti, domande, critiche e suggerimenti poste dagli utenti.
                        <br /><br />
                        Utilizzando i Social Media l’utente accetta il fatto che i dati pubblicati (comprendenti testo, immagini, video) siano visibili liberamente da tutti e, pertanto, la Titolare non può garantire la “Privacy” degli stessi.


                    </p>
                    <br /><br />
                </div>
                <br /><br />

            </section>
        </>

    );
}


export default Disclaimer;