import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

function Lavora() {


    return (
        <>

<section className='page--container' >
                <div className='Grec-title-container'>
                    <h1>Lavora con Noi</h1>
                    <p>
                        Le modalità di gestione del sito in riferimento al trattamento dei dati personali degli utenti che lo consultano.
                    </p>
                </div>
           </section>

<div className='lavora-con-noi' >
< LavoriList />
<ContactForm/>
        
    </div>
         
            <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
         
        
            <br /> <br /> <br /> <br /> <br /> <br />
        </>
    );
}

export default Lavora;

const LavoriList = () => {
    const [lavori, setLavori] = useState([]);
    const [openLavoroId, setOpenLavoroId] = useState(null);

    useEffect(() => {
        const fetchLavori = async () => {
            try {
                const response = await axios.get('http://localhost:3000/api/lavoro'); 
                console.log('API Response:', response.data); 
                setLavori(response.data.docs); 
            } catch (error) {
                console.error('Errore durante il recupero dei lavori:', error);
            }
        };

        fetchLavori();
    }, []);

    const renderRichText = (richText) => {
        return richText.map((node, index) => {
            switch (node.type) {
                case 'h1':
                    return <h1 key={index}>{node.children.map(child => child.text)}</h1>;
                case 'h2':
                    return <h2 key={index}>{node.children.map(child => child.text)}</h2>;
                case 'h3':
                    return <h3 key={index}>{node.children.map(child => child.text)}</h3>;
                case 'p':
                    return <p key={index}>{node.children.map(child => child.text)}</p>;
                case 'ul':
                    return <ul key={index}>{node.children.map((child, i) => <li key={i}>{child.text}</li>)}</ul>;
                case 'ol':
                    return <ol key={index}>{node.children.map((child, i) => <li key={i}>{child.text}</li>)}</ol>;
                case 'link':
                    return <a key={index} href={node.url} target={node.newTab ? '_blank' : '_self'}>{node.children.map(child => child.text)}</a>;
                case 'img':
                    return <img key={index} src={node.url} alt={node.alt} />;
                default:
                    return <span key={index}>{node.children.map(child => child.text)}</span>;
            }
        });
    };

    const handleToggle = (id) => {
        setOpenLavoroId(openLavoroId === id ? null : id);
    };

    return (
        <div className="lavori-list">
            {lavori.map((lavoro) => (
                <div key={lavoro.id} className="lavoro-item">
                    <div className="lavoro-header" onClick={() => handleToggle(lavoro.id)}>
                        <h2>{lavoro.titoloDelLavoro}</h2>
                        {openLavoroId === lavoro.id ? <IoIosArrowUp /> : <IoIosArrowDown />}
                    </div>
                    {openLavoroId === lavoro.id && (
                        <div className="lavoro-content">
                            <p><strong>Dipartimento:</strong> {lavoro.dipartimento}</p>
                            <p><strong>Descrizione del lavoro:</strong> {renderRichText(lavoro.descrizioneDelLavoro)}</p> {/* Render del rich text */}
                            <p><strong>Requisiti:</strong> {lavoro.requisiti}</p>
                            <p><strong>Titolo di studio:</strong> {lavoro.titoloDiStudio}</p>
                            <p><strong>Tipo di contratto:</strong> {lavoro.tipoDiContratto}</p>
                            <p><strong>Data di pubblicazione:</strong> {new Date(lavoro.dataPubblicazione).toLocaleDateString()}</p> {/* Render della data */}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};



const ContactForm = () => {
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      subject: '',
      message: '',
      file: null,
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    const handleFileChange = (e) => {
      setFormData({
        ...formData,
        file: e.target.files[0],
      });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const formDataToSend = new FormData();
      formDataToSend.append('name', formData.name);
      formDataToSend.append('email', formData.email);
      formDataToSend.append('subject', formData.subject);
      formDataToSend.append('message', formData.message);
      if (formData.file) {
        formDataToSend.append('file', formData.file);
      }
  
      try {
        await axios.post('http://localhost:3000/send-email', formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        alert('Email sent successfully');
      } catch (error) {
        console.error('Error sending email:', error);
        alert('Failed to send email');
      }
    };
  
    return (
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} required />
        </div>
        <div>
          <label>Email:</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div>
          <label>Subject:</label>
          <input type="text" name="subject" value={formData.subject} onChange={handleChange} required />
        </div>
        <div>
          <label>Message:</label>
          <textarea name="message" value={formData.message} onChange={handleChange} required></textarea>
        </div>
        <div>
          <label>Attachment:</label>
          <input type="file" name="file" onChange={handleFileChange} />
        </div>
        <button type="submit">Invia Candidatura</button>
      </form>
    );
  };
  