
const cards = [
    {
        title: 'AGITATORI VERTICALI',
        description: 'Installazione sul tetto di vasche o serbatoi di dimensioni opportune, adatti per qualunque tipo di applicazione - con o senza sistema di tenuta. Montati sul tetto del serbatoio. Sono la soluzione più flessibile per ogni applicazione.',
    },
    {
        title: 'AGITATORI LATERALI',
        description: 'Si utilizzano per le applicazioni più semplici in serbatoi di stoccaggio, in particolare di grandi dimensioni - hanno sempre bisogno di un sistema di tenuta. Montati nella parte bassa della parete laterale. Solo per stoccaggi o miscelazioni semplici.',
    },
    {
        title: 'AGITATORI PORTATILI',
        description: 'Fissabili su differenti fusti di volume massimo 1500 litri.',
    },
    {
        title: 'IN LINEA',
        description: 'Per miscelazioni in linea, regolate dalla potenza o dalla portata del fluido.',
    },
    {
        title: 'GIRANTI',
        description: 'Breve elenco delle giranti più utilizzate negli agitatori GREC e loro caratteristiche.',
    },
];

export default cards;