const images = [
    { src: 'https://www.grec.it/images/gallery/azienda/2015-10-01-15.49.41.jpg', category: 'azienda' },
    { src: 'https://www.grec.it/images/gallery/azienda/20150530_103749.jpg', category: 'azienda' },
    { src: 'https://www.grec.it/images/gallery/azienda/20150721_103029.jpg', category: 'azienda' },
    { src: 'https://www.grec.it/images/gallery/azienda/20150827_150116.jpg', category: 'azienda' },
    { src: 'https://www.grec.it/images/gallery/azienda/CAM00578.jpg', category: 'azienda' },
    { src: 'https://www.grec.it/images/gallery/azienda/CAM00579.jpg', category: 'azienda' },
    { src: 'https://www.grec.it/images/gallery/azienda/WP_001547.jpg', category: 'azienda' },
    { src: 'https://www.grec.it/images/gallery/azienda/WP_001549.jpg', category: 'azienda' },
    { src: 'https://www.grec.it/images/gallery/azienda/WP_001558.jpg', category: 'azienda' },
    { src: 'https://www.grec.it/images/gallery/azienda/grec.jpg', category: 'azienda' },
    { src: 'https://www.grec.it/images/gallery/azienda/sfondo.jpg', category: 'azienda' },
    { src: 'https://www.grec.it/images/gallery/fiere/BIOENERGY_2013_1.jpg', category: 'fiere' },
    { src: 'https://www.grec.it/images/gallery/fiere/BIOENERGY_2013_2.jpg', category: 'fiere' },
    { src: 'https://www.grec.it/images/gallery/fiere/DRINKTEC-SIMEI_2017.jpg', category: 'fiere' },
    { src: 'https://www.grec.it/images/gallery/fiere/DRINKTEC-SIMEI_2017_1.jpg', category: 'fiere' },
    { src: 'https://www.grec.it/images/gallery/fiere/SIMEI-2011-2.jpg', category: 'fiere' },
    { src: 'https://www.grec.it/images/gallery/fiere/SIMEI-2013-1.jpg', category: 'fiere' },
    { src: 'https://www.grec.it/images/gallery/fiere/SIMEI_2015_1.jpg', category: 'fiere' }
  ];

  export default images;